import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { Link } from 'gatsby'


const Generic = (props) => (
    <Layout>
        <Helmet>
            <title>Redefining Fleet Management | Mike Weisert | mikew.design</title>
            <meta name="description" content="Redefining Fleet Management" />            
        </Helmet>

        <div id="main" className="alt">
            <section id="banner" className="major fleet">
                <div className="inner">
                    <div className="content">
                    <header className="major">
                        <h1>Redefining Fleet Management</h1>
                        <div className="row">
                            <div className="12u 12u$(small)">Working with the leading company in the Fleet Management space to transform the client experience using their online system.  This project involves a complete redesign of an ordering system which will process $2B+ of vehicle sales annually, as well as future visioning of key tools for clients to proactively manage their fleet.</div>
                            
                        </div><div className="empDates">2016-Present</div>
                    </header>
                            
                    </div>
                </div>
            </section>
            <section id="glance">
            <div className="inner">
                    <div className="content">
                    <h2>Overview</h2> 
                        <div className="grid-wrapper">
                        
                            <div className="col-8">
                                <h3>Achievements</h3>
                                <ul>
                                    <li>Directing multiple UX team members on multiple workstreams</li>
                                    <li>Aligned leadership, stakeholders and the development team to long-term vision for products</li>
                                    <li>Regular presenter at client functions, internal summits and leadership discussions</li>
                                    <li>Created methodology for unmoderrated testing to ensure usability prior to team development</li>
                                    
                                </ul>
                            </div>
                        
                            <div className="col-4" id="quick">
                                <h3>The Client</h3>
                                <ul>
                                    <li>Leader in the Fleet Management space</li>
                                    <li>Processes $2B+ in vehicle sales annually</li>
                                    <li>Multi-year projects to redefine core product offerings</li>
                                    <li>Agile team environment</li>
                                </ul>
                            </div>
                        </div>
                        </div>
                    </div>
            </section>
            <section id="three">
                <div className="inner">
                    <div className="content">
                        
                        <h3>Long Term Vision</h3>
                            <div>
                            Working closely with product and IT, I help the client articulate long term vision for the overall fleet management platform. This often involves 
                            <ul class="sampleList">
                                <li><b>Client Research</b> | Traveled to the organization's client sites across the US.  I conducted 4 hour sessions with clients to better learn how they are interacting with their fleet, and what opportunities we might incorporate into the software.</li>
                                <li><b>Product Ideation</b> | Based on findings, I've define recommendations with the product manager and direct other members of the UX team to create concepts; many of which are currently under development.</li>
                                <li><b>Advocacy</b> | Product recommendations have been presented across the organization, including all levels of leadership to obtain buy in.</li>
                                <li><b>Collaboration</b> | Working with several development teams and product owners in a fast-paced, agile environment.</li>
                            </ul>
                        
                            </div>
                        <h3>Vehicle Ordering</h3>
                            <p>
                                The ordering project is a multi-year, large scale initiative to redefine how the business places vehicle orders for clients.  It involves three primary interfaces:
                                <ol>
                                    <li><b>Ordering Interface</b> | The organization places billions of dollars worth of vehicle orders every year. If a mistake is made by either an internal resource or a client of the organziation during the process, this may result in a loss in the millions. A goal of the project is to rigorously test the interface for usability to avoid error. </li>
                                    <li><b>Catalog Maintenance</b> | Vehicle data is provided by a third party vendor. To reflect accurate pricing and incentive information to clients, an interface is needed for analyists to manipulate and correct information prior to presentation to the client.</li>
                                    <li><b>Order Management</b> | Once an order is received, the organization may need to correct order details or control the delivery of the vehicle to the client.</li>
                                </ol>
                            </p>
                        
                        <h3>The Approach</h3>
                            <p>
                                When I joined the team, the client had attempted to complete the ordering redesign project multiple times in the past.  Where they had struggled was creating the end to end vision to work toward.  Too often the team had developed high-fidelity approaches and began development without considering how all the design elements would work together.
                                I began the project by researching both with clients and internal users.  We learned about existing pain points and opportunities to enhance the experience.  I translated these into user journeys which aligned the team toward our target audiences and their goals.
                                This client works in an Agile environment, which in the past resulted in 'just in time' designs that were often untested and not considerate of future functionality.  
                            </p>    
    
                            <p>    
                                To break this cycle, I implemented an iterative design approach that broadly defined the product in a low-fidelity wireframe.  
                                We used our wireframe to begin testing early and often, both internally and with clients. The feedback we received allowed us to layer on detail as we approached the actual epics and sprints to develop the product.  I also focused on multiple facets of the product at a single time to avoid designing a solution that wasn't modular.
                            </p>
                        <h3>Atomic Design System</h3> 
                            <p>
                                Knowing we were building a modular designing system, we decided to employ an Atomic design approach for our design system.  I work closely with the lead UI designer for the client to properly define the design elements that reside in a single location for the company to reference.  We're currently working with Front-End developers to extend our design system to also include technical implementation details.
                            </p>
                        <h3>Advanced Prototyping &amp; Unmoderated Testing</h3>
                            <p>
                            As functionality was released to end users we encountered issues with usability related to labeling, item placement, and workflow.  The problem involved our testing methodology, which was primarily conducted with a moderator.  When the user was asked to perform tests with a moderator, we found them to be far more likely to put thought into the task at hand.  This differs from how we actually use the web, generally on intuition and scanning visual indicators.  To solve this issue, I created a process for unmoderated testing using Axure, Google Tag Manager, Screen capture software and SurveyMonkey.  Details of this approach was given as part of a Meetup held at Rightpoint.
                                <ul class="sampleList">
                                    <li><a href="https://drive.google.com/file/d/1sEDJmeAv2hZ5hLnJhtsp5yibK7DqfZGZ/view?usp=sharing" target="_blank">Presentation Slides</a></li>
                                    <li><a href="https://drive.google.com/file/d/18sW_W83a9xomHCkPq4oD1kqqCtHMjvE9/view?usp=sharingDeck" target="_blank">Video</a></li>
                                </ul>
                            Using this method, we've been able to quickly validate ideas or challenge a previously held notion in our design when the situation calls for it.
                            </p>
                        
                    </div>
                </div>
            
            </section>
            
            <section id="cta">
                <div className="inner">

                    <ul className="actions fit">
                        <li><Link to="/audio-visual" className="button fit">Back</Link></li>
                        <li><Link to="/ci" className="button fit special">Next</Link></li>
                    </ul>
                </div>        
            </section>
        </div>

    </Layout>
)

export default Generic